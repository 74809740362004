import React from "react"
import Layout from "../components/layout"
import PrivateContent from '../components/privateContent'
import { PaperClipIcon } from '@heroicons/react/solid'

const Admin = () => {
  return (
    <Layout>
      <PrivateContent
        as={AdminsDashboard}
        callbackPath="/admins/"
        rolesAllowed={['admin']}
      />
    </Layout>
  )
}

const AdminsDashboard = () => {
  return (
    <main className="max-w-2xl flex-grow mx-auto flex flex-col justify-around">
      <div className="sm:flex sm:flex-row-reverse sm:items-center">
        <div className="sm:px-2">
          <h1 className="px-4 pt-5 text-2xl text-left text-teal-500 font-bold sm:text-3xl">
            Admins Dashboard
          </h1>
          <p className="px-4 mt-8 text-xl text-gray-700 sm:mt-8">
            Congratulations! You've made it to 'admin' status. Yay! 😎
          </p>
          <p className="px-4 mt-8 text-xl text-gray-700 sm:mt-8">
            There's not actually much here, but the <em>ONLY</em> way you
            can see it is by logging in and having the `admin` role set for
            your user! (Something you generally can't just click a button
            for ordinarily) Here's a random kitty though!
          </p>
          <div className="bg-white px-4 py-5 ">
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <h2 className="text-sm font-medium text-gray-500">Attachments</h2>
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">resume_back_end_developer.pdf</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">coverletter_back_end_developer.pdf</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin